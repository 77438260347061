<template>
  <VModalWidget :dismiss="dismiss" :submit="doSubmit" :submitting="working" :submitTitle="this.$t('create')">
    <template v-slot:title>{{ $t('labels.user') }}</template>

    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <v-text-field
          v-model.trim="form.firstname"
          :error-messages="firstnameErrors"
          v-bind:label="$t('labels.firstname')"
          required
          @input="$v.form.firstname.$touch()"
          @blur="$v.form.firstname.$touch()"
        />
        <v-text-field
          v-model.trim="form.lastname"
          :error-messages="lastnameErrors"
          v-bind:label="$t('labels.lastname')"
          required
          @input="$v.form.lastname.$touch()"
          @blur="$v.form.lastname.$touch()"
        />
        <v-text-field
          v-model.trim="form.login"
          :error-messages="loginErrors"
          v-bind:label="$t('labels.login')"
          required
          @input="$v.form.login.$touch()"
          @blur="$v.form.login.$touch()"
        />
        <v-text-field
          v-model.trim="form.email"
          :error-messages="emailErrors"
          v-bind:label="$t('labels.email')"
          required
          @input="$v.form.email.$touch()"
          @blur="$v.form.email.$touch()"
        />
        <v-text-field
          v-model.trim="form.password"
          :error-messages="passwordErrors"
          v-bind:label="$t('labels.password')"
          type="password"
          required
          @input="$v.form.password.$touch()"
          @blur="$v.form.password.$touch()"
        />
        <FirmField v-model="form.firm_id" :errors="firmIdErrors" />
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { VModalWidget, BaseForm, FormErrors } from '@argon/app/components'
import { Modal } from '@argon/app/mixins'
import { NAMESPACE } from '@argon/iam/users/store'
export default {
  name: 'ModalNewUser',
  extends: BaseForm,
  mixins: [Modal],
  components: {
    VModalWidget,
    FormErrors
  },
  validations: {
    form: {
      firstname: { required },
      lastname: { required },
      login: { required },
      password: { required },
      email: { required, email },
      firm_id: {}
    }
  },
  data: () => ({
    returnPath: { name: 'systemUsers' },
    form: {
      firstname: '',
      lastname: '',
      login: '',
      password: '',
      email: '',
      firm_id: null
    },
    working: false
  }),
  computed: {
    firstnameErrors() {
      const errors = []
      if (!this.$v.form.firstname.$dirty) return errors
      !this.$v.form.firstname.required && errors.push(this.$t('required'))
      this.getServerErrors('firstname', errors)
      return errors
    },
    lastnameErrors() {
      const errors = []
      if (!this.$v.form.lastname.$dirty) return errors
      !this.$v.form.lastname.required && errors.push(this.$t('required'))
      this.getServerErrors('lastname', errors)
      return errors
    },
    loginErrors() {
      const errors = []
      if (!this.$v.form.login.$dirty) return errors
      !this.$v.form.login.required && errors.push(this.$t('required'))
      this.getServerErrors('login', errors)
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.required && errors.push(this.$t('required'))
      this.getServerErrors('password', errors)
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.required && errors.push(this.$t('required'))
      !this.$v.form.email.email && errors.push(this.$t('invalidEmail'))
      this.getServerErrors('email', errors)
      return errors
    },
    firmIdErrors() {
      const errors = []
      if (!this.$v.form.firm_id.$dirty) return errors
      !this.$v.form.firm_id.required && errors.push(this.$t('labels.required'))
      this.getServerErrors('firm_id', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['createUser']),
    doSubmit() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.working = true
      this.createUser(this.form)
        .then((resp) => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.working = false
        })
    }
  }
}
</script>
